import location from "../assets/images/location.svg";
import moniter from "../assets/images/moniter.svg";
import languages from "../assets/images/languages.svg";
import clock from "../assets/images/clock.svg";
import calendar from "../assets/images/calendar.svg";
import user from "../assets/images/user.svg";

import { Col, Row } from "react-bootstrap";

const BatchesCard = () => {
  return (
    <>
      {/* Card Start  */}
      <div className="upcoming-batches-card">
        <Row>
          <Col>
            <div className="calendar-card">
              <h1 className="date">17</h1>
              <span className="month">November</span>
            </div>
          </Col>
          <Col xl={10} lg={10} className="d-flex align-items-center">
            <Row>
              <Col xl={5} lg={12} className="d-flex align-items-center">
                <h1 className="title">UX UI Design Course Program</h1>
              </Col>

              <Col xl={{ span: 6, offset: 1 }} lg={12}>
                <Row>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={calendar}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">3 Months</span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={languages}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                        className="lang"
                      />
                      <span className="ms-3">Tamil & English</span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={clock}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">10:00 AM - 1:00 PM</span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={user}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">Slots Left - 05</span>
                    </p>
                  </Col>

                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={location}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">T.Nagar, Chennai</span>
                    </p>
                  </Col>

                  <Col md={6}>
                    <p className="course-list mt-0 mb-md-0">
                      <img
                        src={moniter}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">Both Online & Offline</span>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {/* Card end */}

      {/* Card Start  */}
      <div className="upcoming-batches-card">
        <Row>
          <Col>
            <div className="calendar-card">
              <h1 className="date">24</h1>
              <span className="month">November</span>
            </div>
          </Col>
          <Col xl={10} lg={10} className="d-flex align-items-center">
            <Row>
              <Col xl={5} lg={12} className="d-flex align-items-center">
                <h1 className="title">UX UI Design Course Program</h1>
              </Col>

              <Col xl={{ span: 6, offset: 1 }} lg={12}>
                <Row>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={calendar}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">3 Months</span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={languages}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                        className="lang"
                      />
                      <span className="ms-3">Tamil & English</span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={clock}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">10:00 AM - 1:00 PM</span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={user}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">Slots Left - 05</span>
                    </p>
                  </Col>

                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={location}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">T.Nagar, Chennai</span>
                    </p>
                  </Col>

                  <Col md={6}>
                    <p className="course-list mt-0 mb-md-0">
                      <img
                        src={moniter}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">Both Online & Offline</span>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {/* Card end */}

      {/* Card Start  */}
      <div className="upcoming-batches-card">
        <Row>
          <Col>
            <div className="calendar-card">
              <h1 className="date">01</h1>
              <span className="month">December</span>
            </div>
          </Col>
          <Col xl={10} lg={10} className="d-flex align-items-center">
            <Row>
              <Col xl={5} lg={12} className="d-flex align-items-center">
                <h1 className="title">UX UI Design Course Program</h1>
              </Col>

              <Col xl={{ span: 6, offset: 1 }} lg={12}>
                <Row>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={calendar}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">3 Months</span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={languages}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                        className="lang"
                      />
                      <span className="ms-3">Tamil & English</span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={clock}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">10:00 AM - 1:00 PM</span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={user}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">Slots Left - 05</span>
                    </p>
                  </Col>

                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={location}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">T.Nagar, Chennai</span>
                    </p>
                  </Col>

                  <Col md={6}>
                    <p className="course-list mt-0 mb-md-0">
                      <img
                        src={moniter}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">Both Online & Offline</span>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {/* Card end */}

      {/* Card Start  */}
      <div className="upcoming-batches-card">
        <Row>
          <Col>
            <div className="calendar-card">
              <h1 className="date">08</h1>
              <span className="month">December</span>
            </div>
          </Col>
          <Col xl={10} lg={10} className="d-flex align-items-center">
            <Row>
              <Col xl={5} lg={12} className="d-flex align-items-center">
                <h1 className="title">UX UI Design Course Program</h1>
              </Col>

              <Col xl={{ span: 6, offset: 1 }} lg={12}>
                <Row>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={calendar}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">3 Months</span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={languages}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                        className="lang"
                      />
                      <span className="ms-3">Tamil & English</span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={clock}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">10:00 AM - 1:00 PM</span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={user}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">Slots Left - 05</span>
                    </p>
                  </Col>

                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={location}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">T.Nagar, Chennai</span>
                    </p>
                  </Col>

                  <Col md={6}>
                    <p className="course-list mt-0 mb-md-0">
                      <img
                        src={moniter}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">Both Online & Offline</span>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {/* Card end */}
      {/* Card Start  */}
      <div className="upcoming-batches-card">
        <Row>
          <Col>
            <div className="calendar-card">
              <h1 className="date">15</h1>
              <span className="month">December</span>
            </div>
          </Col>
          <Col xl={10} lg={10} className="d-flex align-items-center">
            <Row>
              <Col xl={5} lg={12} className="d-flex align-items-center">
                <h1 className="title">UX UI Design Course Program</h1>
              </Col>

              <Col xl={{ span: 6, offset: 1 }} lg={12}>
                <Row>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={calendar}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">3 Months</span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={languages}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                        className="lang"
                      />
                      <span className="ms-3">Tamil & English</span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={clock}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">10:00 AM - 1:00 PM</span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={user}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">Slots Left - 05</span>
                    </p>
                  </Col>

                  <Col md={6}>
                    <p className="course-list mt-0 mb-3">
                      <img
                        src={location}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">T.Nagar, Chennai</span>
                    </p>
                  </Col>

                  <Col md={6}>
                    <p className="course-list mt-0 mb-md-0">
                      <img
                        src={moniter}
                        alt="UI UX Academy in Chennai"
                        title="UI UX Design Institute in Chennai"
                      />
                      <span className="ms-3">Both Online & Offline</span>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {/* Card end */}
    </>
  );
};

export default BatchesCard;
